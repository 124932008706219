import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import Banner from '../components/Banner'
import fbc from '../assets/images/fbc.png'
import fbclogo from '../assets/images/logoyellowwhite.png'
import inf from '../assets/images/dashboard.png'
import inflogo from '../assets/images/logo-whit.png'

class HomeIndex extends React.Component {
    render() {
        return (
            <Layout pageTitle="Home" metaDescription="White Bear Homepage" metaKeywords={['home']}>
                <Banner />
                <div id="main">
                    <section id="one" className="tiles2">
                        <article>
                            <div className="big">
                                <header className="major">
                                    <img src={fbclogo} alt="Feedback Centre Logo" />
                                </header>
                                <p> Feedback Centre is THE essential tool when it comes to collecting customer data. Whether you’re dealing with patient feedback or engaging with tenants, our award-winning Feedback Centre allows you to communicate with your clients continuously and securely all whilst maintaining your own unique brand.
                                </p>
                                <Link to="/feedback-centre" className="button small">READ MORE</Link>
                            </div>
                            <div>
                                <span className="container">
                                    <img src={fbc} alt="Feedback Centre screenshot" />
                                </span>
                            </div>
                        </article>
                        <article>
                            <div className="big">
                                <header className="major">
                                    <img src={inflogo} alt="Informatics" />
                                </header>
                                <p>Informatics offers you a customisable dashboard with evidence-based reporting. From Friends and Family Test metrics, through to analysing your qualitative data utilising sentiment analysis, Informatics places the data in your hands enabling you to make those all-important decisions.</p>
                                <Link to="/informatics" className="button small">READ MORE</Link>
                            </div>
                            <div>
                                <span className="container">
                                    <img src={inf} alt="Informatics screenshot" />
                                </span>
                            </div>
                        </article>
                    </section>

                </div>
            </Layout>
        )
    }
}

export default HomeIndex