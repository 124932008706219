import React from 'react'
const Banner = (props) => (
    <section id="banner" className="major">
        <div className="inner">
            <header className="major">
                <h1>We are White Bear.</h1>
            </header>
            <div>
                <p>Our products help you understand your customers using meaningful engagement, measurable feedback and data insight.</p>
            </div>
        </div>
    </section>
)

export default Banner
